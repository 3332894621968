import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Anchor text usualy use text labels or a text label and icon pairing to convey an action to a user. The most important thing to consider when working with anchor text is to ensure that their appearance and placement convey their intended action clearly to a user.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/anchor1.svg",
      "alt": "banner guideline anchor"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Icons left or right :`}</strong>{` can consist of an icon on its own or an icon paired with a text label. Always ensure that the icon you select for your anchor text clearly communicates the action.`}</li>
      <li parentName="ol"><strong parentName="li">{`Text labels :`}</strong>{` are the core element, text labels should be concise while effectively conveying what will occur when the anchor text is clicked.`}</li>
    </ol>
    <br />
    <br />
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/anchor2.svg",
      "alt": "banner guideline anchor 2"
    }}></img>
    <p>{`Anchor text can consist of an icon on its own or an icon paired with a text label. Always ensure that the icon you select for your anchor text clearly communicates the action. Icons paired with text labels should directly support the text label.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With icon" caption="Use icons and text labels that support each other with use the icon only on one side only" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/anchor3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : With multiple icon" caption="Use icons and text labels that do not relate to one another or use multiple icons alongside a text label" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/anchor4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Generally anchor text are supportive elements that work alongside other page elements to convey navigation to the user. Place anchor text in close proximity to the content they are supporting so that the relationship between anchor text and the content they relate to is clear.`}</p>
    <p><strong parentName="p">{`Common places anchor text appear:`}</strong>{` Alongside body copy, Alongside images, Within cards, With navbars and breadcrumbs, Footers`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> To offer less important actions users can take.</li>
      <li> To show many possible actions at once. </li>
      <li> When the action is navigation inside the current app. </li>
      <li>
        {" "}
        Involves an interaction (like open a modal or submitting a form).{" "}
      </li>
      <li> With short action text. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> When you have a single action you want to draw attention to. </li>
      <li> To make text inside paragraphs or lists actionable. </li>
      <li> For navigation to external sites and services. </li>
      <li> With a long call to action. </li>
      <li> So users can sign in using a social service. </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Make sure your anchor used a match color and contrast from main of your primary, seconndary or accent color, and consider of minnimum size of text label is 12px, icon 12px with margin in 12px. for accept the accecissible and touch target of users.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With margin" caption="Provide visual distinction between surrounding body label and clickable anchor text visible with much margin" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/anchor5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : without margin" caption="Neglect to provide visual distinction between body copy and clickable anchor text makes distract vibility of call to action" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/anchor6.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`Copywriting for anchor text needs to be specific, recognizable, and only one or two words long (if possible).`}</p>
    <ul>
      <li parentName="ul">{`Use action verbs: When users read an action verb, they know what the anchor will do. Especially, if it’s followed by specific connotations.`}</li>
      <li parentName="ul">{`Use task-specific language: A generic copy cause uncertainty in users. They’re unsure what the button will do if the copy on doesn’t specify.`}</li>
      <li parentName="ul">{`Use active imperative form: In order to make the copy concise, the active imperative form turns verb phrases into commands. We can drop the subject and articles which are not needed, and only include a verb with an adverb or direct object.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With short copy" caption="Use the spesific copy of action in label when click the achor with help visual context from icon" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/anchor7.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : With long copy" caption="Use to long copy in label make the label can’t represent the spesific action when the users click the anchor text" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/anchor8.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      